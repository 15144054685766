'use client';

import Image from 'next/legacy/image';
import { Container, Typography, Box, styled, useTheme } from '@mui/material';
import FullWidthSection from ':components/FullWidthSection';
import MoGrid from ':components/MoGrid';
import PricingTable from ':components/PricingTable';
import CoursesSummary from ':components/CoursesSummary';
import CourseCard from ':components/CourseCard';
import MoLink from ':components/MoLink';
import MoButtonLink from ':components/MoButtonLink';
import CourseCarousel from ':components/CourseCarousel';
import { useSuspenseQuery } from '@apollo/client';
import { CourseTypeEnum } from ':src/lookup';
import { continuingEducationSummaryQuery, coursesQuery } from ':src/apollo/courses/queries';
import {
  ContinuingEducationSummaryQueryQuery,
  CoursesQueryQuery,
} from ':src/__generated__/graphql';
import useViewer from ':src/hooks/useViewer';

const HeroBanner = styled('div')(({ theme }) => ({
  marginTop: -32,
  backgroundImage: `url(${process.env.NEXT_PUBLIC_IMGIX_HOSTNAME}/static/hero-image.png?auto=format)`,
  backgroundPosition: 'right -200px top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.up('sm')]: {
    marginTop: -64,
  },

  [theme.breakpoints.up('md')]: {
    backgroundSize: 'contain',
    backgroundPosition: 'right -150px center',
  },

  [theme.breakpoints.up('lg')]: {
    backgroundPosition: 'right center',
  },
}));

const HeroHeader = styled(Typography)(({ theme }) => ({
  fontSize: 30,

  [theme.breakpoints.up('sm')]: {
    fontSize: 42,
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
}));

const PhoneImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },

  '& > *': {
    position: 'absolute !important',
    margin: 'auto !important',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}));

export default function HomePage() {
  const { viewer, isLoggedIn } = useViewer();
  const theme = useTheme();

  const {
    data: { courses: newCourses },
  } = useSuspenseQuery<CoursesQueryQuery>(coursesQuery, {
    variables: {
      limit: 3,
      offset: 0,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: false,
    },
  });

  const {
    data: { courses: flagshipCourses },
  } = useSuspenseQuery<CoursesQueryQuery>(coursesQuery, {
    variables: {
      limit: 3,
      offset: 0,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: true,
    },
  });

  const {
    data: { courses: allCourses },
  } = useSuspenseQuery<CoursesQueryQuery>(coursesQuery, {
    variables: {
      limit: 6,
      offset: 3,
      courseTypeId: CourseTypeEnum.ON_DEMAND,
      flagship: false,
    },
  });

  const {
    data: { continuingEducationSummary },
  } = useSuspenseQuery<ContinuingEducationSummaryQueryQuery>(continuingEducationSummaryQuery);

  return (
    <>
      {isLoggedIn ? (
        <Container>
          <Box mb={6}>
            <Box mb={6}>
              <CoursesSummary summary={continuingEducationSummary} />
            </Box>
            {continuingEducationSummary.inProgressOnDemandCourse && (
              <>
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="h3">Return to Learning, {viewer.firstName}</Typography>
                  <MoLink href="/profile/courses" underline="always">
                    View my courses
                  </MoLink>
                </Box>
                <CourseCard course={continuingEducationSummary.inProgressOnDemandCourse} />
              </>
            )}
          </Box>
        </Container>
      ) : (
        <HeroBanner>
          <Container>
            <Box py={{ xs: 8, md: 16 }} width={{ xs: '80%', sm: '60%' }}>
              <HeroHeader variant="h1" mb={1}>
                Continuing education through meaningful dialogue
              </HeroHeader>
              <Typography display={{ xs: 'none', sm: 'block' }} maxWidth={400}>
                Motivo Learning is a collection of courses that provide clinicians the tools to
                thrive in today’s landscape.
              </Typography>
              <Box mt={2}>
                <MoButtonLink href="/signup">Start learning</MoButtonLink>
              </Box>
            </Box>
          </Container>
        </HeroBanner>
      )}

      <Box mb={6}>
        <FullWidthSection>
          <Typography variant="h3" mb={2}>
            New courses
          </Typography>
          <CourseCarousel courses={newCourses.result} />
        </FullWidthSection>
      </Box>

      <Container>
        <Box mb={8}>
          <Typography variant="h3" mb={2}>
            Motivo picks
          </Typography>
          {flagshipCourses && <CourseCarousel courses={flagshipCourses.result} />}
        </Box>

        <Box mb={8}>
          <Typography variant="h3" mb={2}>
            All courses
          </Typography>
          <MoGrid container>
            {allCourses?.result.map((course) => (
              <MoGrid item sm={6} md={4} key={course.id}>
                <CourseCard course={course} isVertical />
              </MoGrid>
            ))}
          </MoGrid>
          <Box mt={4} mb={8} textAlign="center">
            <MoLink href="/courses" variant="body3">
              See more
            </MoLink>
          </Box>
        </Box>
      </Container>

      {!isLoggedIn && (
        <Box mb={{ xs: -4, sm: -8 }}>
          <FullWidthSection backgroundColor={theme.palette.green[50]}>
            <MoGrid container>
              <MoGrid item sm={6}>
                <PhoneImageWrapper>
                  <Image
                    src="static/iphone-screenshot.png?auto=format"
                    layout="fixed"
                    objectFit="cover"
                    width={240}
                    height={492}
                    alt="iPhone screenshot of website"
                  />
                </PhoneImageWrapper>
              </MoGrid>
              <MoGrid item sm={6}>
                <Box
                  maxWidth={{ sm: 380 }}
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <PricingTable />
                </Box>
              </MoGrid>
            </MoGrid>
          </FullWidthSection>
        </Box>
      )}
    </>
  );
}
